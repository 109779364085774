import { Button, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "../utilities/formatCurrency"
import { LineItem } from "../managers/OrderManager"

export function CartItemComponent({ productId = "", price = 0, quantity, metadata }: LineItem) {
  const { removeFromCart, decreaseCartQuantity, increaseCartQuantity } = useShoppingCart()

  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img
        src={metadata?.previewImageUrls}
        style={{ width: "100px", height: "100px", objectFit: "contain" }}
      />
      <div className="me-auto">
        <div>
          {"Product Name"}
          <div className="text-muted" style={{ fontSize: ".75rem" }}>
            {formatCurrency(price)}
          </div>
        </div>
        <div>
          <span className="text-muted" >
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => decreaseCartQuantity(productId)}>
              -
            </Button>
            &nbsp;
            {quantity}
            &nbsp;
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => increaseCartQuantity(productId)}>
              +
            </Button>
          </span>
        </div>
      </div>
      <div> {formatCurrency(price * quantity)}</div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => removeFromCart(productId)}
      >
        &times;
      </Button>
    </Stack>
  )
}