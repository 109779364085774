import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const OrderError: React.FC = () => {
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error);
    } else {
      setError('An unknown error occurred.');
    }
  }, [location.state]);

  return (
    <Container>
      <Row className="mb-4">
        <h1>Order Error</h1>
        <p>{error}</p> {/* Display the error message */}
      </Row>
    </Container>
  );
};

export default OrderError;
