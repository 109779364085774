import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

interface Auth0ProviderWrapperProps {
    children: ReactNode;
}

const Auth0ProviderWrapper: React.FC<Auth0ProviderWrapperProps> = ({ children }) => {
    const domain = 'wallscapes.us.auth0.com';
    const audience = 'https://api.wallscapes.ai/dev';
    const clientId = 'qkBtWMC5imycy6iOKdb04arGmIFdD4Vt';

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin + '/callback',
                audience: audience
            }}
        >
            {children}
        </Auth0Provider>
    );
};
export default Auth0ProviderWrapper;