import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ImageList from './products/component/ImageList';

const HomePage: React.FC = () => {
  return (
    <Container>
      <Row>
        <div>
          <h1>Transform Your Space with AI-Generated Art.</h1>
          <p>Discover Unique Wall Tapestries, Posters, Flags, and More.</p>
          <ImageList />
        </div>
      </Row>
    </Container>
  );
};

export default HomePage;