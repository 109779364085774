import React, { useEffect, useState, useRef, useCallback } from 'react';
import useImageApi from '../../../managers/ImageManager';
import { Container, Row, Col } from 'react-bootstrap';
import Loading, { LoadingStates } from '../../../components/Loading';

const ImageListComponent: React.FC = () => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getImages } = useImageApi();
  const [imagesData, setImagesData] = useState<any[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const baseUrl = "https://dev-images.wallscapes.ai/";

  const isFetching = useRef(false); // Prevents multiple fetches at the same time
  const hasMoreImages = useRef(true); // Tracks whether there are more images to load
  const observer = useRef<IntersectionObserver | null>(null); // Holds the IntersectionObserver

  // Fetch images function
  const fetchImages = useCallback(async () => {
    if (isFetching.current || !hasMoreImages.current) return;

    isFetching.current = true; // Block further fetches
    setLoadingState(LoadingStates.LOADING);

    try {
      const data = await getImages(limit);
      if (data.length === 0) {
        hasMoreImages.current = false; // No more images to load
      } else {
        setImagesData((prevImages) => [...prevImages, ...data]); // Append new images to the list
      }
      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage("Error fetching products: " + error);
      console.error('Error fetching images:', error);
    } finally {
      isFetching.current = false; // Reset the fetching flag
    }
  }, [limit, getImages]);

  // Effect to fetch initial images when component mounts
  useEffect(() => {
    if (imagesData.length === 0) {
      fetchImages(); // Fetch initial images when the component mounts
    }
  }, [fetchImages]);

  // IntersectionObserver callback for the last image
  const lastImageElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect(); // Clean up previous observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetching.current && hasMoreImages.current) {
        setLimit((prevLimit) => prevLimit + 10); // Increase limit by 10 when the last image is in view
      }
    }, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    });

    if (node) observer.current.observe(node); // Attach the observer to the last image
  }, []);

  if (loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) {
    return <div><Loading state={loadingState} message={loadingMessage} /></div>;
  }

  if (!imagesData || imagesData.length === 0) {
    return <div>No Images found.</div>;
  }

  return (
    <Container>
      <Row>
        {imagesData.map((image, index) => {
          if (imagesData.length === index + 1) {
            // Attach observer to the last image
            return (
              <Col key={index} xs={12} sm={6} md={4} lg={3} ref={lastImageElementRef}>
                <div className="image-item mb-4">
                  <img
                    src={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format`}
                    srcSet={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={`AI Generated Image ${index}`}
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </Col>
            );
          } else {
            return (
              <Col key={index} xs={12} sm={6} md={4} lg={3}>
                <div className="image-item mb-4">
                  <img
                    src={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format`}
                    srcSet={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={`AI Generated Image ${index}`}
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </Col>
            );
          }
        })}
      </Row>
    </Container>
  );
};

export default ImageListComponent;
