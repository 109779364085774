interface ThrottleRequest {
  type: 'global' | 'user' | 'guest';
  metric: 'limit' | 'count'
  serviceName: string;
}

interface UsageRequest {
  userID?: number;
  serviceName: string;
}

const useThrottler = () => {

  const getPhase = (params: ThrottleRequest): number => {
    const totalSpent = Math.floor(Math.random() * 5000);
    if (totalSpent < 1000) return 1;
    if (totalSpent < 2500) return 2;
    if (totalSpent < 5000) return 3;
    return 4;
  };

  const getTotalUsage = (serviceName: string): number => {
    return Math.floor(Math.random() * 5000);
  };

  const getPhaseLimit = (serviceName: string): number => {
    const currentPhase = getPhase({ type: 'global', metric: 'limit', serviceName: serviceName });
    switch (currentPhase) {
        case 1: return 3; // Phase 1: Up to 3 images/day
        case 2: return 2; // Phase 2: Up to 2 images/day
        case 3: return 1; // Phase 3: Up to 1 image/day
        case 4: return 0; // Phase 4: No more images
        default: return 0
    }
  };

  const getUserUsage = (params: UsageRequest): number => {
    const currentUserLimit = 2;
    return 2;
  }

  const getUserLimit = (params: UsageRequest): number => {
    const currentPhaseLimit = getPhaseLimit(params?.serviceName);
    const currentUserLimit = getUserUsage({userID: 1234, serviceName: params?.serviceName });
    return currentUserLimit <= currentPhaseLimit ? currentUserLimit : 0; 
  };

  return { getPhase, getTotalUsage, getPhaseLimit, getUserUsage, getUserLimit };
};

export default useThrottler;