import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Badge, ListGroup, ListGroupItem, Card } from 'reactstrap';
import useOrderApi, { Order } from '../../managers/OrderManager';
import { useParams } from 'react-router-dom';
import Loading, { LoadingStates } from '../../components/Loading';
import { formatCurrency } from '../../utilities/formatCurrency'; // Hypothetical utility for currency formatting

const OrderDetail: React.FC = () => {
  const { getOrder } = useOrderApi();
  const { orderId = '' } = useParams();
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [orderDetails, setOrderDetails] = useState<Order | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderData: Order = await getOrder(orderId);
        setOrderDetails(orderData);
      } catch (error) {
        setError('Failed to retrieve order details.');
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  if (isLoading) {
    return <div><Loading message='Loading order details...' /></div>;
  }

  if (error) {
    return <div><Loading state={LoadingStates.ERROR} message={error} /></div>;
  }

  if (!orderDetails) {
    return <div>No order details found.</div>;
  }

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">Order Confirmation</h1>
          <p className="text-center">Thank you for your order! Here are your order details.</p>
        </Col>
      </Row>

      {/* Order Details Section */}
      <Row className="mb-4">
        <Col md={6} className="mb-3">
          <Card body className="border-0 shadow-sm">
            <h2>Order Details</h2>
            <p><strong>Order ID:</strong> {orderDetails.orderId}</p>
            <p><strong>Total Price:</strong> {formatCurrency(orderDetails.amountTotal)}</p>
            <p><strong>Total Shipping:</strong> {formatCurrency(orderDetails.amountShipping)}</p>
            <p><strong>Total Tax:</strong> {formatCurrency(orderDetails.amountTax)}</p>
            <p><strong>Total Discount:</strong> {formatCurrency(orderDetails.amountDiscount)}</p>
            <p><strong>Currency:</strong> {orderDetails.currency}</p>
          </Card>
        </Col>

        {/* Shipping Address Section */}
        <Col md={6} className="mb-3">
          <Card body className="border-0 shadow-sm">
            <h2>Shipping Address</h2>
            <p>{orderDetails.shippingDetails?.name}</p>
            <p>{orderDetails.shippingDetails?.address?.address1}</p>
            {orderDetails.shippingDetails?.address?.address2 && <p>{orderDetails.shippingDetails?.address?.address2}</p>}
            <p>{orderDetails.shippingDetails?.address?.city}, {orderDetails.shippingDetails?.address?.state}, {orderDetails.shippingDetails?.address?.postalCode}</p>
            <p>{orderDetails.shippingDetails?.address?.country}</p>
            <p><strong>Email:</strong> {orderDetails.shippingDetails?.email}</p>
            <p><strong>Phone:</strong> {orderDetails.shippingDetails?.phone}</p>
          </Card>
        </Col>
      </Row>

      {/* Line Items Section */}
      <Row className="mb-4">
        <Col>
          <Card body className="border-0 shadow-sm">
            <h2>Line Items</h2>
            <ListGroup>
              {orderDetails.lineItems?.map((item, index) => (
                <ListGroupItem key={index} className="d-flex align-items-center">
                  <Col xs="3" className="p-0">
                    {/* Optional Product Image */}
                    <img src={item?.metadata?.previewImageUrls} alt={item.catalogItemId} className="img-fluid" />
                  </Col>
                  <Col>
                    <p><strong>Product ID:</strong> {item.catalogItemId}</p>
                    <p><strong>Variant ID:</strong> {item.variantId}</p>
                    <p><strong>Quantity:</strong> {item.quantity}</p>
                    <p><strong>Price:</strong> {formatCurrency(item.price)}</p>
                  </Col>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>

      {/* Order Status Section */}
      <Row>
        <Col>
          <Card body className="text-center border-0 shadow-sm">
            <h2>Order Status</h2>
            <Badge color={orderDetails.status === 'submitted' ? 'success' : 'warning'} className="p-2">
              {orderDetails?.status?.toUpperCase()}
            </Badge>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetail;
