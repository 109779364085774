import React, { useEffect, useRef, useState } from 'react';
import { Row, Container, Spinner } from 'react-bootstrap';
import useOrderApi from '../../managers/OrderManager';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import usePaymentApi from '../../managers/PaymentManager';

const Success: React.FC = () => {
  const { createOrder, submitOrder } = useOrderApi();
  const navigate = useNavigate();
  const { clearCart, cartItems } = useShoppingCart();
  const { getPaymentSessionId, clearPaymentSessionId } = usePaymentApi();

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error message

  const isSubmitting = useRef(false); // Ref to track submission state

  useEffect(() => {
    const submitOrderAfterPayment = async () => {
      if (isSubmitting.current) return; // Prevent multiple submissions
      isSubmitting.current = true; // Mark as submitting to prevent further submissions

      try {
        // Validate payment session
        const sessionId = await getPaymentSessionId();
        if (!sessionId) {
          throw new Error('Payment Session Id not found');
        }

        let initialOrder = await createOrder(cartItems);
        initialOrder.payment = { paymentSessionId: sessionId, vendorName: 'stripe' };

        // Submit the order using the payment session
        const submittedOrder = await submitOrder(initialOrder);
        if (!submittedOrder) {
          throw new Error('Error submitting order');
        }

        console.log('Order successfully submitted:', submittedOrder);
        clearCart(); // Clear the cart after successful submission
        clearPaymentSessionId(); // Clear the payment session ID after submission

        // Navigate to the order confirmation page
        navigate(`/order/${submittedOrder.orderId}`, { state: { orderId: submittedOrder.orderId } });
      } catch (error: any) {
        console.error('Error submitting order:', error.message);
        setErrorMessage(`There was a problem submitting your order. (${error.message})`);
      } finally {
        isSubmitting.current = false; // Reset the submission flag
      }
    };

    submitOrderAfterPayment();
  }, []);

  return (
    <Container>
      <Row>
        <h1>Payment Successful</h1>
        {errorMessage ? (
          <p>{errorMessage}</p> // Show error message if there is an error
        ) : (
          <p>Your payment has been processed successfully. Submitting order...</p>
        )}
        <Spinner />
      </Row>
    </Container>
  );
};

export default Success;
