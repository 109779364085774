import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const location = useLocation();

  if (isLoading) {
    return <Outlet />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <Outlet />
};

export default ProtectedRoute;
