import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0Callback: React.FC = () => {
  const { handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();
  const [loadingMessage, setLoadingMessage] = useState("Loading...");

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const { appState } = await handleRedirectCallback();
        const returnTo = appState?.returnTo || '/';
        console.log("Navigating to:" + returnTo)
        navigate(returnTo); // Redirect to the intended page after successful authentication
      } catch (error) {
        setLoadingMessage("Error handling redirect callback: " + error)
        console.error('Error handling redirect callback:', error);
      }
    };

    handleAuth();
  }, [handleRedirectCallback, navigate]);

  return <div>{loadingMessage}</div>;
};

export default Auth0Callback;
