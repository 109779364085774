import { createContext, ReactNode, useContext, useState } from "react"
import { ShoppingCart } from "../components/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { LineItem } from "../managers/OrderManager"

type ShoppingCartProviderProps = {
  children: ReactNode
}

type ShoppingCartContext = {
  openCart: () => void
  closeCart: () => void
  clearCart: () => void
  getItemQuantity: (productId: string) => number
  addToCart: (item: LineItem) => void
  removeFromCart: (productId: string) => void
  increaseCartQuantity: (productId: string) => void
  decreaseCartQuantity: (productId: string) => void
  cartQuantity: number
  cartItems: LineItem[]
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}
export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [cartItems, setCartItems] = useLocalStorage<LineItem[]>(
    "shopping-cart",
    []
  )

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity, 0
  )

  const openCart = () => {
    setIsOpen(true)
  }
  const closeCart = () => setIsOpen(false)
  function getItemQuantity(productId: string) {
    return cartItems.find(item => item.productId === productId)?.quantity || 0
  }
  function addToCart(item: LineItem) {
    // if the item exists in the cart, increment the quantity by 1
    const existingCartItem = cartItems.find((i: LineItem) => i.productId === item.productId)
    if (existingCartItem) {
      increaseCartQuantity(item.productId)
    } else {
      setCartItems([...cartItems, item])
    }
  }
  function removeFromCart(productId: string) {
    setCartItems(currItems => {
      return currItems.filter(item => item.productId !== productId)
    })
  }
  function increaseCartQuantity(productId: string = "") {
    setCartItems((currItems: any) => {
      if (currItems.find((item: LineItem) => item.productId === productId) == null) {
        return [...currItems, { productId, quantity: 1 }]
      } else {
        return currItems.map((item: LineItem) => {
          if (item.productId === productId) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decreaseCartQuantity(productId: string = "") {
    setCartItems(currItems => {
      if (currItems.find(item => item.productId === productId)?.quantity === 1) {
        return currItems.filter(item => item.productId !== productId)
      } else {
        return currItems.map(item => {
          if (item.productId === productId) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }

  function clearCart() {
    setCartItems([])
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        addToCart,
        removeFromCart,
        increaseCartQuantity,
        decreaseCartQuantity,
        openCart,
        closeCart,
        clearCart,
        cartItems,
        cartQuantity
      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  )
}