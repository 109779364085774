import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

interface ImageRequestParams {
  prompt: string;
  imageShape: string;
  imageStyle: string;
  vendorId: string;
}

interface ApiUrls {
  [key: string]: string;
}

const openAiImageApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/image',
  dev: 'https://api.wallscapes.ai/dev/image',
  prod: 'https://api.wallscapes.ai/prod/image'
};

const productImageApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/product',
  dev: 'https://api.wallscapes.ai/dev/product',
  prod: 'https://api.wallscapes.ai/prod/product'
};

const imageApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/image',
  dev: 'https://api.wallscapes.ai/dev/image',
  prod: 'https://api.wallscapes.ai/prod/image'
};

const getOpenAiImageApiUrl = () => {
  const environment = getEnvironment();
  return openAiImageApiUrls[environment];
};

const getProductImageApiUrl = () => {
  const environment = getEnvironment();
  return productImageApiUrls[environment];
};

const getImageApiUrl = () => {
  const environment = getEnvironment();
  return imageApiUrls[environment];
};

const useImageApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const generateImage = async (params: ImageRequestParams): Promise<any> => {
    const endpointUrl = getOpenAiImageApiUrl();
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(endpointUrl, params, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status) {
        return response.data.data;
      } else {
        throw new Error('No image URL found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve image URL from the API');
    }
  };

  const getImages = async (limit: number = 10): Promise<any[]> => {
    const endpointUrl = `${getImageApiUrl()}?limit=${limit}`;
    try {
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response?.status === 200 && response?.data?.status === 'success' && response?.data?.data) {
        return response.data.data;
      } else {
        throw new Error('No image data found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve image data from the API');
    }
  };

  return { generateImage, getImages };
};

export default useImageApi;
