import React, { useState } from 'react';
import { Button, Form, Input, Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import useImageApi from '../../managers/ImageManager';
import useThrottler from '../../managers/ThrottleManager';
import useProductApi from '../../managers/ProductManager';
import Loading, { LoadingStates } from '../../components/Loading';

const Generate: React.FC = () => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.SUCCESS);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [productImgUrl, setProductImgUrl] = useState('/logo512.png');
  const [isModalOpen, setModalOpen] = useState(false);

  const [prompt, setPrompt] = useState('A futuristic car driving in the city');
  const [imageShape, setImageShape] = useState('square');
  const [imageStyle, setImageStyle] = useState('vivid');
  const { generateProduct } = useProductApi();
  const { generateImage } = useImageApi();
  const { getPhase, getTotalUsage, getPhaseLimit, getUserUsage, getUserLimit } = useThrottler();

  // Handler for input value change
  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  // Handler for aspect ratio change
  const handleImageShapeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageShape(event.target.value);
  };

  const handleImageStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageStyle(event.target.value);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingMessage("Generating AI Image...");
    setLoadingState(LoadingStates.LOADING);
    try {
      const image = await generateImage({ prompt: prompt, vendorId: "openai", imageShape: imageShape, imageStyle: imageStyle });
      setLoadingState(LoadingStates.SUCCESS);
      if (image?.imageUrl) {
        console.log('Generated image URL:', image?.imageUrl);
        setLoadingMessage("Generating Product Preview...");
        setLoadingState(LoadingStates.LOADING);
        const product = await generateProduct({ sourceImageUrl: image?.imageUrl, sourceImageId: image?.imageId });
        if (product && Array.isArray(product.previewImageUrls) && product.previewImageUrls.length > 0) {
          const imageUrl = product.previewImageUrls[0];
          if (imageUrl) {
            console.log('Product image URL:', imageUrl);
            setProductImgUrl(imageUrl);
            toggleModal();
          } else {
            console.error('Product has previewImageUrls, but no valid image URL found.');
            setProductImgUrl('/logo256.png'); // Fallback image
            throw new Error('Product image URL is invalid.');
          }
        } else {
          console.error('Product or previewImageUrls is invalid:', product);
          setProductImgUrl('/logo256.png'); // Fallback image
          throw new Error('Product Preview Image Generation Failed. Please check the product object.');
        }
        setLoadingState(LoadingStates.SUCCESS);
      } else {
        throw new Error('AI Image Generation Failed.');
      }
    } catch (error: any) {
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage(error?.message);
      console.log(error);
    }
  };

  return (
    <Container className="h-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col md="6" className="text-center w-100">
          <h1>Generate your own Wallscape!</h1>
          <p>Type in the description of what you would like your wallscape to look like. You are only limited by your imagination (and AI)!</p>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="8" className="m-0 p-0">
                <Input id='prompt' name='prompt' type='text' value={prompt} onChange={handlePromptChange} />
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageShape} onChange={handleImageShapeChange}>
                  <option value="square">Square</option>
                  <option value="horizontal">Horizontal</option>
                  <option value="vertical">Vertical</option>
                </Input>
              </Col>
              <Col md="2" className="m-0 p-0">
                <Input type="select" value={imageStyle} onChange={handleImageStyleChange}>
                  <option value="vivid">Vivid</option>
                  <option value="natural">Natural</option>
                </Input>
              </Col>
            </Row>
            <Button className="w-100" type='submit'>Generate</Button>
          </Form>
          {(loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && (
            <div><Loading state={loadingState} message={loadingMessage} /></div>
          )}
          <br />
          <Modal isOpen={isModalOpen} toggle={toggleModal} className="modal-fullscreen">
            <ModalBody className="d-flex align-items-center justify-content-center">
              <img src={productImgUrl} alt='Generated Product' className='img-fluid' />
              <Button color="secondary" className="position-absolute top-0 end-0 m-2" onClick={toggleModal}>Close</Button>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Generate;
