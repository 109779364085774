// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Custom from './views/products/Custom';
import Products from './views/products/Products';
import ProtectedRoute from './auth/ProtectedRoute';
import Login from './views/auth/LoginUniversal';
import LoginCallback from './auth/Auth0Callback';
import { Navigation } from './components/Navigation';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import Success from './views/payment/Success';
import Cancel from './views/payment/Cancel';
import OrderDetail from './views/order/OrderDetail';
import OrderError from './views/order/OrderError';

const App: React.FC = () => {

  return (
    <ShoppingCartProvider>
      <Router>
        <Navigation />
        <main style={{ padding: '16px' }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="" element={<Home />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/callback" element={<LoginCallback />} />
            <Route path="/products" element={<ProtectedRoute />}>
              <Route path="" element={<Products />} />
              <Route path="custom" element={<Custom />} />
            </Route>
            <Route path="/payment">
              <Route path="success" element={<Success />} />
              <Route path="cancel" element={<Cancel />} />
            </Route>
            <Route path="/order">
              <Route path="error" element={<OrderError />} />
              <Route path=":orderId" element={<OrderDetail />} />
            </Route>
          </Routes>
        </main>
      </Router>
    </ShoppingCartProvider>
  );
};

export default App;
