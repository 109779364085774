import React from 'react'
import { Container, Row } from 'react-bootstrap'
import ProductList from './component/ProductList'

const HomePage: React.FC = () => {
  return (
    <Container>
      <Row>
        <h1>Products</h1>
        <ProductList />
      </Row>
    </Container >
  )
}

export default HomePage