import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import usePaymentApi from '../../managers/PaymentManager';
import { LineItem } from '../../managers/OrderManager';

// Define the props for the CheckoutButton component
interface CheckoutButtonProps {
  cartItems: LineItem[];
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ cartItems }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { createPaymentSession, getStripe, setPaymentSessionId } = usePaymentApi();

  const handleCheckout = async () => {
    try {
      setMessage('');
      setLoading(true);

      // Create the payment session
      const session = await createPaymentSession(cartItems);
      if (!session || !session.id) {
        throw new Error('Failed to create Stripe session');
      }
      // Save session ID to sessionStorage
      await setPaymentSessionId(session.id)

      // Resolve stripePromise and ensure Stripe is loaded
      const stripe = await getStripe();
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
        setMessage(result.error.message || '');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during checkout:', error);
      setMessage('Error during checkout. Please try again.');
    }
  };

  return (
    <>
      <button disabled={isLoading || !cartItems || cartItems.length === 0} onClick={handleCheckout}>
        {isLoading && <Spinner size="sm" />} Checkout
      </button>
      {message && <p style={{ color: 'red' }}>{message}</p>}
    </>
  );
};

export default CheckoutButton;
