import React from 'react';

// Define constants for the states
export const LoadingStates = {
  ERROR: 'Error',
  LOADING: 'Loading',
  SUCCESS: 'Success',
} as const;

type LoadingProps = {
  message?: string;
  state?: typeof LoadingStates[keyof typeof LoadingStates];
};

const Loading: React.FC<LoadingProps> = ({ message = "Loading...", state = LoadingStates.LOADING }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {state === LoadingStates.LOADING && (
        <>
          <div className="spinner-border text-primary" role="status"></div>
          <small className="mt-1">{message}</small>
        </>
      )}
      {state === LoadingStates.ERROR && (
        <>
          <div className="alert alert-danger" role="alert">
            <strong>Error:</strong> {message}
          </div>
        </>
      )}
      {state === LoadingStates.SUCCESS && (
        <>
          <div className="alert alert-success" role="alert">
            <strong>Success:</strong> {message}
          </div>
        </>
      )}
    </div>
  );
};

export default Loading;