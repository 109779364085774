import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Modal, ModalBody } from 'react-bootstrap';
import useProductApi from '../../../managers/ProductManager';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { formatCurrency } from '../../../utilities/formatCurrency';
import Loading, { LoadingStates } from '../../../components/Loading';

const ProductList: React.FC = () => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getRecentProducts } = useProductApi();
  const [products, setProducts] = useState<any[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const isFetching = useRef(false); // Prevents multiple fetches at the same time
  const hasMoreProducts = useRef(true); // Tracks whether there are more products to load
  const observer = useRef<IntersectionObserver | null>(null); // Holds the IntersectionObserver
  const [productImgUrl, setProductImgUrl] = useState('/logo512.png');
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    getItemQuantity,
    addToCart,
    removeFromCart,
    openCart
  } = useShoppingCart();

  // Fetch products function
  const fetchProducts = useCallback(async () => {
    if (isFetching.current || !hasMoreProducts.current) return;

    isFetching.current = true; // Block further fetches
    setLoadingState(LoadingStates.LOADING);

    try {
      const data = await getRecentProducts(limit);
      if (data.length === 0) {
        hasMoreProducts.current = false; // No more products to load
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data]); // Append new products to the list
      }
      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage("Error fetching products: " + error);
      console.error('Error fetching products:', error);
    } finally {
      isFetching.current = false; // Reset the fetching flag
    }
  }, [limit, getRecentProducts]);

  // Effect to fetch initial products when component mounts
  useEffect(() => {
    if (products.length === 0) {
      fetchProducts(); // Fetch initial products when the component mounts
    }
  }, [fetchProducts]);

  const handleImageClick = (productUrl: string) => {
    console.log(productUrl)
    setProductImgUrl(productUrl)
    toggleModal()
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // IntersectionObserver callback for the last product
  const lastProductElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect(); // Clean up previous observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetching.current && hasMoreProducts.current) {
        setLimit((prevLimit) => prevLimit + 10); // Increase limit by 10 when the last product is in view
      }
    }, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    });

    if (node) observer.current.observe(node); // Attach the observer to the last product
  }, []);

  if (loadingState === LoadingStates.LOADING && products.length === 0) {
    return <div><Loading state={loadingState} message={loadingMessage} /></div>;
  }

  if (!products || products.length === 0) {
    return <div>No products found.</div>;
  }

  return (
    <Container>
      <Row>
        {products.map((product, index) => {
          const quantity = getItemQuantity(product.productId);
          return (
            <Col key={index} xs={12} sm={6} md={4} lg={3} ref={products.length === index + 1 ? lastProductElementRef : null}>
              <Card className="mb-4">
                <Card.Img
                  variant="top"
                  src={product.previewImageUrls}
                  alt={`Product ${index}`}
                  loading="lazy"
                  className="img-fluid"
                  onClick={() => handleImageClick(product.previewImageUrls[0])}
                />
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-baseline mb-3">
                    <span className="fs-5">{product.name || product.title}</span>
                    <span className="ms-2 text-muted">{formatCurrency(product?.price)}</span>
                  </Card.Title>
                  <div className="mt-auto">
                    <div className="d-flex align-items-center flex-column" style={{ gap: ".5rem" }}>
                      {quantity === 0 ? (
                        <Button className="w-100" onClick={() => addToCart({ productId: product?.productId, catalogItemId: product?.catalogItemId, price: product?.price, quantity: 1, vendorProductId: product?.vendorData?.id, vendorVariantId: 46153, metadata: { ...product?.metadata, previewImageUrls: product?.previewImageUrls } })}>
                          + Add To Cart
                        </Button>
                      ) : (
                        <Button
                          onClick={() => removeFromCart(product.productId)}
                          className="w-100"
                          variant="secondary"
                        >
                          - Remove From Cart
                        </Button>
                      )}
                      <Button
                        onClick={() => openCart()}
                        className="w-100"
                        variant="outline"
                        size="sm"
                      >
                        View Cart
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Modal show={isModalOpen} onHide={toggleModal} fullscreen>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <img src={productImgUrl} alt="Generated Product" className="img-fluid" />
          <Button variant="secondary" className="position-absolute top-0 end-0 m-2" onClick={toggleModal}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Container >
  );
};

export default ProductList;
