import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from './EnvironmentManager';

export interface Order {
  orderId?: string;
  userId: string;
  createdDate?: Date;
  updatedDate?: Date;
  externalId?: string;
  lineItems: LineItem[];
  shippingDetails?: ShippingDetails;
  amountSubtotal?: number;
  amountTax?: number;
  amountDiscount?: number;
  amountShipping?: number;
  amountTotal?: number;
  currency?: 'USD';
  payment?: Payment;
  status: 'pending' | 'submitted' | 'complete' | 'error';
}

export interface LineItem {
  productId?: string;
  catalogItemId: string;
  variantId?: string;
  vendorProductId?: string;
  vendorVariantId?: number;
  quantity: number;
  itemData?: any;
  price?: number;
  metadata?: any;
  fulfillmentStatus?: 'fulfilled' | 'unfulfilled';
}

export interface Address {
  country: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface ShippingDetails {
  name: string;
  email?: string;
  phone?: string;
  address: Address;
  method?: string;
}

export interface Payment {
  paymentSessionId: string;
  vendorName: string;
  status?: string;
  paymentType?: string;
  amountTotal?: number;
  metadata?: any;
}

interface ApiUrls {
  [key: string]: string;
}

const orderApiUrls: ApiUrls = {
  localhost: 'http://127.0.0.1:5000/products/recent',
  dev: 'https://api.wallscapes.ai/dev/order',
  prod: 'https://api.wallscapes.ai/prod/order'
};

const getOrderApiUrl = () => {
  const environment = getEnvironment();
  return orderApiUrls[environment];
};

const useOrderApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const createOrder = async (cartItems: LineItem[]): Promise<Order> => {
    let order: Order = {
      userId: "0",
      lineItems: cartItems,
      status: 'pending'
    }
    return order
  }

  const submitOrder = async (order: Order): Promise<Order> => {
    const endpointUrl = getOrderApiUrl();
    order.status = 'submitted';
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(endpointUrl, order, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      if ((response?.status === 200 || response?.status === 201) && response?.data?.data) {
        return response.data.data as Order;
      } else {
        throw new Error('No Order found in the response or status is not success.');
      }
    } catch (error: any) {
      console.log('Error submitting an order:', error?.message);
      throw new Error('Failed to create Order');
    }
  };

  const updateOrder = async (order: Order): Promise<Order> => {
    const endpointUrl = getOrderApiUrl();
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.put(endpointUrl, order, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if ((response?.status === 200) && response?.data?.data) {
        return response.data.data as Order;
      } else {
        throw new Error('No Order found in response');
      }
    } catch (error: any) {
      console.log('Error updating the order:', error?.message);
      throw new Error('Failed to update the order');
    }
  };

  const getOrder = async (orderId: string): Promise<Order> => {
    const endpointUrl = `${getOrderApiUrl()}/${orderId}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      if ((response?.status === 200) && response?.data?.data) {
        return response.data.data as Order;
      } else {
        throw new Error('No order data found in the response or status is not success.');
      }
    } catch (error: any) {
      console.error('Error calling API:', error?.message);
      throw new Error('Failed to retrieve order data from the API');
    }
  };

  return { createOrder, submitOrder, getOrder, updateOrder };
};

export default useOrderApi;
