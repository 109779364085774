// src/Login.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const returnTo = (location.state as { from: string })?.from || '/';
    navigate(location.pathname, { replace: true }); // Replace the current entry in the history stack
    loginWithRedirect({
      appState: {
        returnTo: returnTo
      },
    });
  }, [loginWithRedirect]);

  return <div>Redirecting to login...</div>;
};

export default Login;
